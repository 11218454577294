// Alerts
//

@each $color, $value in $theme-colors {
  @if $color != primary and $color != light {
    @include appwork-alert-variant('.alert-#{$color}', if($color=='dark' and $dark-style, $white, $value));
    @include appwork-alert-dark-variant('.alert-dark-#{$color}', if($color=='dark' and $dark-style, $white, $value));
  }
}

// *******************************************************************************
// * Dark style

@if $dark-style {
  .alert {
    border-color: transparent !important;
  }
}

// *******************************************************************************
// * RTL

@include rtl-only {
  .alert-dismissible {
    padding-right: $alert-padding-x;
    padding-left: ($close-font-size + $alert-padding-x * 2);
  }

  .alert-dismissible .close {
    right: auto;
    left: 0;
  }
}
